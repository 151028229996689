import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faBug, faCommentDots} from "@fortawesome/free-solid-svg-icons";
import StarRatingComponent from 'react-star-rating-component';
import axios from 'axios';
import {DZTOOLS_URL} from "../config/Config"

interface MessageProps {
    show: boolean;
    onHide: () => void;
    type: 'Bug' | 'Suggestion' | 'Feedback';
}

function getCorrespondentMessage(type: string): string {
  let message = "";

  switch (type) {
    case "Bug":
      message = "Please describe the bug in explicit steps so we can fix it.";
      break;
    case "Suggestion":
      message = "Let us know what you'd like to improve. Your suggestions are valuable to us.";
      break;
    case "Feedback":
      message = "Share your thoughts with us. What do you think about our service?";
      break;
    default:
      message = "We appreciate your message. Please specify the purpose of your message.";
      break;
  }

  return message;
}


function Message({ show, onHide, type } : MessageProps) {
  const [message, setMessage] = useState('');
  const [rating, setRating] = useState<number>(0);

  const clearMessageModal = () => {
    if(type === "Feedback"){
      setRating(0);
    }
    setMessage("")
  }

  async function handleSubmit(type:string,message:string, rating:number){
    const username = localStorage.getItem("username") ?? "";
    if(type !== "Feedback"){
      rating = 0;
    }
    try {
      const response = await axios.post(DZTOOLS_URL + "/submit-message", {
        type,
        message,
        username,
        creationDate: new Date(),
        rating,
      });
  
      if (response.status === 201) {
        alert("Message submited");
        clearMessageModal();
        onHide();
        return response.data; // Return the response data upon success
      } else {
        throw new Error("Failed to submit AdminMessage");
      }
    } catch (error) {
      throw error; // Rethrow the error for the caller to handle
    }
  }


  return (
    <Modal show={show} onHide={onHide} centered classname="sidebar-modals">
      <Modal.Header closeButton>
        <Modal.Title>
          {type === 'Bug' ? (
              <>
                  <FontAwesomeIcon icon={faBug} /> Report Bug
              </>
          ) : type === 'Suggestion' ? (
              <>
                  <FontAwesomeIcon icon={faPenToSquare} /> Make Suggestions
              </>
          ) : (
              <>
                  <FontAwesomeIcon icon={faCommentDots} /> Provide Feedback
              </>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="messageText">
          <div>
            <strong>{getCorrespondentMessage(type)}</strong>
            <div style={{marginTop: "1%", fontSize: "24px"}}>
            {type === "Feedback" && (
              <StarRatingComponent
                name="rating"
                starCount={5} // Adjust as needed
                value={rating}
                onStarClick={(newRating) => setRating(newRating)}
              />
            )}
            </div>
          </div>
          <br/>
          <Form.Control
            as="textarea"
            rows={5}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            maxLength={500}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button disabled={!message || (type === "Feedback" && !rating) } variant="success" onClick={() =>handleSubmit(type,message,rating)}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Message;
