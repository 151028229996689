import React from "react";
import { Modal, Form, Button } from "react-bootstrap";

interface DeleteUserModalProps {
  show: boolean;
  handleClose: () => void;
  userToDelete: User | null;
  handleDeleteUser: () => void;
}

interface User {
    _id: string;
    username: string;
    password: string;
    subsEndDate: Date;
    role: string;
  }

function DeleteUserModal(props: DeleteUserModalProps) {
    const { show, handleClose, userToDelete, handleDeleteUser } = props;

  return (
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="p-del">Are you sure you want to delete the user: {userToDelete?.username}?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteUser}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
  );
}

export default DeleteUserModal;
