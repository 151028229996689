import React from "react";
import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Container, Row, Col, Card, Nav, Navbar } from "react-bootstrap"; // Import react-bootstrap components
import UsersAdmin from "./UsersAdmin";
import Messages from "./Messages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff, faHome } from "@fortawesome/free-solid-svg-icons";
import Logout from "../Logout";
import { Link } from "react-router-dom";
import AuthenticationService from "../../backend/services/AuthenticationService";
import { useNavigate } from "react-router-dom";
import "../../css/Admin.css";
import axios from "axios";
import {DZTOOLS_URL} from "../config/Config"

interface AdminDashboardProps {
  username: string | null;
}

function AdminDashboard({ username }: AdminDashboardProps) {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const navigate = useNavigate();
  const [sumOfGeneratedFiles, setSumOfGeneratedFiles] = useState<number | null>(null);
  const [selectedMessageType, setSelectedMessageType] = useState(''); // Default: All Messages
  const [totalDonations, setTotalDonations] = useState<string | null>(null);

  const handleLogout = () => {
    AuthenticationService.logout();
    AuthenticationService.setUser(null);
    setShowLogoutModal(false);
    navigate("/login");
  };

  useEffect(() => {
    async function fetchAndDisplaySum() {
      try {
        // Get the authentication token from localStorage
        const authToken = localStorage.getItem('authToken');
            
        if (!authToken) {
          console.error("Authentication token not found in localStorage");
          return;
        }
        console.log("DZTOOLS URL:",DZTOOLS_URL)
        const response = await axios.post(DZTOOLS_URL + "/users", null, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
    
        if (response.status === 200) {
            const users = response.data
            const sum = users.reduce((total: any, user: { generatedFiles: any; }) => total + user.generatedFiles, 0);
            setSumOfGeneratedFiles(sum);
            console.log("Sum of items:",sum);
        }
        
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    }

    fetchAndDisplaySum();
    fetchAndDisplayDonations();
  }, []);

  async function fetchAndDisplayDonations() {
    try{
      const response = await axios.get(DZTOOLS_URL + "/check-total-donations");
      if (response.status === 200) {
        // Update the user list with the new data
        setTotalDonations(response.data);
      } else {
        console.error("Error fetching total files:", response.data);
      }
    }catch (error) {
      console.error(`Error: ${error}`);
    }
  }


  const handleMessageTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMessageType(e.target.value);
  }

  return (
    <div>
      <Navbar className="custom-navbar" bg="light" expand="lg">
      <Navbar.Brand className="brand" href="#home">
            <div style={{marginBottom: "5px", marginLeft: "30%"}}>
              <span >Admin Dashboard</span>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" style={{ marginRight: "15%"}}>
            <Nav className="ms-auto">
              <h4 style={{ marginTop: "6px", marginRight: "15px" }}>
                {username}
              </h4>
              <Nav.Item>
                <Link to="/typesEditor" className="home-button-admin">
                  <FontAwesomeIcon className="nav-admin-button" icon={faHome} />
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="" className="home-button">
                  <FontAwesomeIcon
                    icon={faPowerOff}
                    className="nav-admin-button"
                    onClick={() => setShowLogoutModal(true)}
                  />
                </Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
      </Navbar>
      <Container className="admin-dashboard">
        <Row style={{ marginTop: "1%" }}>
          {/* List of Users (2/3) */}
          <Col>
            <UsersAdmin />
          </Col>
        </Row>
        <br/>
        <Row>
          {/* Statistics Card (1/3) */}
          <Col md={3}>
            <Card className="admin-card">
              <Card.Header>
                <h4>Statistics</h4>
              </Card.Header>
              <Card.Body>
                <div>
                  <strong>Files Generated:</strong>{" "} {sumOfGeneratedFiles !== null ? sumOfGeneratedFiles : "Loading..."}
                </div>
                <div>
                  <strong>Donations:</strong>{" "} {totalDonations !== null ? totalDonations : "Loading..."} €
                </div>
              </Card.Body>
            </Card>
          </Col>

          
          {/* Messages/Logs */}
          <Col md={9}>
            <Card className="admin-card admin-messages">
              <Card.Header style={{display:"flex", justifyContent: "space-between"}}>
                <h4>Messages / Logs</h4>
                <select value={selectedMessageType} onChange={handleMessageTypeChange}>
                  <option value="">All</option>
                  <option value="bug">Bug</option>
                  <option value="subscription">Subscription</option>
                  <option value="registration">Registration</option>
                  <option value="suggestion">Suggestion</option>
                  <option value="feedback">Feedback</option>
                </select>
              </Card.Header>
              <Card.Body>
                <Messages selectedMessageType={selectedMessageType}/>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row></Row>

        
      </Container>
      {showLogoutModal && (
        <Logout
          show={showLogoutModal}
          handleClose={() => setShowLogoutModal(false)}
          handleLogout={handleLogout} // You should define this function
        />
      )}
    </div>
  );
}

export default AdminDashboard;
