class ItemLists {
    categoryList: string[];
    usageList: string[];
    valueList: string[];
  
    constructor(numTiers: number) {
      this.categoryList = [
        "weapons",
        "food",
        "clothes",
        "tools",
        "containers",
        "vehiclesparts",
        "explosives",
      ];
  
      this.usageList = [
        "Village",
        "School",
        "Hunting",
        "Office",
        "Prison",
        "Police",
        "Military",
        "Underground",
        "Lunapark",
        "SeasonalEvent",
      ];

      this.valueList = [];

      for (let i = 1; i <= numTiers; i++) {
        this.valueList.push(`Tier${i}`);
      }
    }
  }
  
  export default ItemLists;
  