"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearAuthToken = exports.logout = exports.clearUser = exports.setUser = exports.getCurrentUser = exports.setIsAuthenticated = exports.getIsAuthenticated = void 0;
// authService.js
var isAuthenticated = !!localStorage.getItem('authToken');
var currentUser = JSON.parse(localStorage.getItem('currentUser') || 'null');
var getIsAuthenticated = function () { return isAuthenticated; };
exports.getIsAuthenticated = getIsAuthenticated;
var setIsAuthenticated = function (value) {
    isAuthenticated = value;
    // Update localStorage with the authentication status
    if (!value) {
        (0, exports.clearAuthToken)();
        (0, exports.clearUser)();
    }
};
exports.setIsAuthenticated = setIsAuthenticated;
var getCurrentUser = function () {
    return currentUser;
};
exports.getCurrentUser = getCurrentUser;
var setUser = function (user) {
    currentUser = user;
    // Update localStorage with the user data
    localStorage.setItem('currentUser', JSON.stringify(user));
};
exports.setUser = setUser;
var clearUser = function () {
    currentUser = null;
    // Clear user data from localStorage
    localStorage.removeItem('currentUser');
};
exports.clearUser = clearUser;
var logout = function () {
    // Perform any cleanup or session management here
    (0, exports.clearUser)();
    (0, exports.clearAuthToken)();
};
exports.logout = logout;
var clearAuthToken = function () {
    // Here, you should clear the authentication token, typically by removing it from localStorage or any other storage mechanism.
    // This is a simplified example using localStorage.
    localStorage.removeItem('authToken'); // Replace 'authToken' with the key you use for your authentication token
    // After clearing the token, also clear the current user, assuming you manage the user data in your user service.
    (0, exports.setUser)(null); // Set the current user to null or perform similar actions based on your app's logic
};
exports.clearAuthToken = clearAuthToken;
// Add any additional authentication-related functions here
exports.default = {
    getIsAuthenticated: exports.getIsAuthenticated,
    setIsAuthenticated: exports.setIsAuthenticated,
    getCurrentUser: exports.getCurrentUser,
    setUser: exports.setUser,
    clearUser: exports.clearUser,
    logout: exports.logout,
};
