import React, { useState, useEffect } from "react";
import "font-awesome/css/font-awesome.min.css";
import xml2js from "xml-js";
import { AutoSizer, List } from "react-virtualized";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faDownload, faFloppyDisk, faSortUp, faSortDown, faRotateLeft, faFileCode} from "@fortawesome/free-solid-svg-icons";
import { Button, Form, Row, Spinner } from "react-bootstrap";
import CustomModal from './modals/CustomModal';

import "../css/TableStyles.css";

interface XmlTableEditorProps {
  xmlData: string | null;
  onUpdateGeneratedFiles: () => void;
  resetXmlData: () => void;
  isDarkMode: boolean;
  onUploadTypesClick: () => void;
  handleXmlFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  xmlFileInputRef: React.RefObject<HTMLInputElement>;
  unlockGenerateButton: boolean;
}

interface TableDataItem {
  element: string;
  content: string;
}

interface TableDataItem {
  id: string;
  Name: string;
  Nominal: string; // Uppercase property names
  Min: string;
  Lifetime: string;
  Restock: string;
  QtMin: string;
  QtMax: string;
  Cost: string;
  Category: string;
  Usage: string;
  Value: string;
}

interface Change {
  id: string;
  columnName: keyof TableDataItem;
  originalValue: string;
  newValue: string;
  saved: boolean;
  isMultiplierChange: boolean;
}

function parseXmlData(parsedXml: any): any[] {
  const extractedData: any[] = [];

  if (parsedXml.types && parsedXml.types.type) {
    const types = Array.isArray(parsedXml.types.type)
      ? parsedXml.types.type
      : [parsedXml.types.type];

    types.forEach((type: any) => {
      const name = type._attributes?.name || "";
      const nominal = type.nominal?._text || "";
      const lifetime = type.lifetime?._text || "";
      const restock = type.restock?._text || "";
      const min = type.min?._text || "";
      const quantmin = type.quantmin?._text || "";
      const quantmax = type.quantmax?._text || "";
      const cost = type.cost?._text || "";

      const category = Array.isArray(type.category)
        ? type.category.map((item: any) => item._attributes.name).join(", ")
        : type.category
        ? type.category._attributes.name
        : "";

      const usage = Array.isArray(type.usage)
        ? type.usage.map((item: any) => item._attributes.name).join(", ")
        : type.usage
        ? type.usage._attributes.name
        : "";

      const value = Array.isArray(type.value)
        ? type.value.map((item: any) => item._attributes.name).join(", ")
        : type.value
        ? type.value._attributes.name
        : "";

      const rowData = {
        Name: name,
        Nominal: nominal,
        Min: min,
        Lifetime: lifetime,
        Restock: restock,
        QtMin: quantmin,
        QtMax: quantmax,
        Cost: cost,
        Category: category,
        Usage: usage,
        Value: value,
      };

      extractedData.push(rowData);
    });
  }

  return extractedData;
}

function XmlTableEditor({
  xmlData,
  onUpdateGeneratedFiles,
  resetXmlData,
  isDarkMode,
  onUploadTypesClick,
  handleXmlFileUpload,
  xmlFileInputRef,
  unlockGenerateButton
}: XmlTableEditorProps) {
  const [xmlObject, setXmlObject] = useState<any>(null);
  const [updatedXmlData, setUpdatedXmlData] = useState<any>(null);
  const [tableData, setTableData] = useState<TableDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(true); // State for loading indicator
  // State to track sorting column and order
  const [sortOrder, setSortOrder] = useState({
    column: "Name", // Set the default sorting column (e.g., "Name")
    ascending: true, // Set the default sorting order
  });
  type TableColumn = "Nominal" | "Min" | "Lifetime" | "Restock";

  // State variable for filtered data
  const [nameFilter, setNameFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [usageFilter, setUsageFilter] = useState("");
  const [valueFilter, setValueFilter] = useState("");
  const [originalData, setOriginalData] = useState<TableDataItem[]>([]);
  const [filteredData, setFilteredData] = useState<TableDataItem[]>([]);

  // State variable to track edited data
  const [editedData, setEditedData] = useState<{ rowId: string; columnName: keyof TableDataItem; newValue: string ; saved:boolean ; isMultiplierChange:boolean}[]>([]);
  const [filteredTableData, setFilteredTableData] = useState<TableDataItem[]>(originalData);

  const [isDirty, setIsDirty] = useState(false); // Track whether there are unsaved changes
  const [isMultiplierClicked, setIsMultiplierClicked] = useState(false); // Track whether there are unsaved changes
  const [isCellBeingEdited, setIsCellBeingEdited] = useState(false); // Track whether there are unsaved changes
  const [isResetting, setIsResetting] = useState(false);
  // Define a state variable to track edited cells
  const [editedCells, setEditedCells] = useState<string[]>([]);
  const [changesSaved, setChangesSaved] = useState(true);
  const [changeStack, setChangeStack] = useState<Change[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [selectedPercentage, setSelectedPercentage] = useState(1); // Initial value is 100%
  const [isGenerationComplete, setIsGenerationComplete] = useState(false); // Track whether there are unsaved changes
  const resetXmlEditor = () => {
    console.log("entered RESET");
    setIsResetting(true);
    resetXmlData();
  
    // Reset all state variables to their initial values
    setXmlObject(null);
    setUpdatedXmlData(null);
    setTableData([]);
    setIsLoading(true);
    setSortOrder({
      column: "Name",
      ascending: true,
    });
    setNameFilter("");
    setCategoryFilter("");
    setUsageFilter("");
    setValueFilter("");
    setOriginalData([]);
    setFilteredData([]);
    setEditedData([]);
    setEditedCells([]);
    setChangeStack([]);
    setShowModal(false);
    setModalMessage('');
    setIsDirty(false);
    setIsMultiplierClicked(false);
    setIsCellBeingEdited(false);
    clearFilters();
  }

  const percentageOptions = [
    { label: '100%', value: 1 },
    { label: '50%', value: 0.50 },
    { label: '25%', value: 0.25 },
    { label: '10%', value: 0.10 },
  ];

  const handlePercentageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = parseFloat(event.target.value);
    setSelectedPercentage(selectedValue);
  };

  const clearFilters = () => {
    setNameFilter("");
    setCategoryFilter("");
    setUsageFilter("");
    setValueFilter("");
  };

  const getUniqueValues = (columnName: keyof TableDataItem) => {
    const allValues = tableData
      .map((item) =>
        (item[columnName] as string).split(", ").map((value) => value.trim())
      )
      .flat();
    // Use Set to store distinct values
    const uniqueValues = Array.from(new Set(allValues));

    return uniqueValues;
  };

  const handleMultiplierChange = (column: TableColumn, action: string) => {
    setIsDirty(true);
    setChangesSaved(false);
    setIsMultiplierClicked(true);
    setIsCellBeingEdited(false);
    
    const computedFilteredData = getSortedAndFilteredData();

    // Set the state with the computed filtered data
    setFilteredData(computedFilteredData);
    // Update the tableData by applying the multiplier/divisor to the specified column
   // Get the selected percentage value
    const selectedMultiplier = selectedPercentage;

   // Update the tableData by applying the selected multiplier/divisor to the specified column
    const updatedTableData = tableData.map((rowData) => {
      if (computedFilteredData.some((filteredRow) => filteredRow.id === rowData.id)) {
        // Apply the multiplier only to rows present in filteredData
        const currentValue = parseFloat(rowData[column as keyof TableDataItem]);
      let newValue;

      if (action === "up") {
        newValue = Math.round(currentValue * (1 + selectedMultiplier)).toString(); // Increase by selected percentage
      } else if (action === "down") {
        // Ensure the value doesn't go below 1 or the original value divided by the selected multiplier
        newValue = Math.round(Math.max(1, (currentValue / (1 + selectedMultiplier)))).toString(); // Decrease by selected percentage
      } else {
        return rowData; // No change if the action is neither "up" nor "down"
      }
        // Call updateData to update the specific cell value
        updateData(rowData.id, column as keyof TableDataItem, newValue, true);

        return {
          ...rowData,
          [column]: newValue,
        };
      } else {
        // Return unchanged rows for rows not present in filteredData
        return rowData;
      }
    });
  
     // Get all the cell identifiers for the edited column
    const columnCellIdentifiers = updatedTableData.map((rowData) => `${rowData.id}-${column}`);

    // Set the editedCells state to all the cell identifiers in the column
    //setEditedCells(columnCellIdentifiers);
    setEditedCells(columnCellIdentifiers);
    // Update the tableData state
    setTableData(updatedTableData);
  
    // Update the editedData state to track changes made by multipliers (similar to handleInputChange)
    updatedTableData.forEach((updatedRowData) => {
      Object.entries(updatedRowData).forEach(([key, value]) => {
        const originalValue = tableData.find((row) => row.id === updatedRowData.id)?.[key as keyof TableDataItem];
        if (originalValue !== undefined && originalValue !== value) {
          const existingChangeIndex = editedData.findIndex(
            (change) =>
              change.rowId === updatedRowData.id && change.columnName === key
          );
  
          if (existingChangeIndex !== -1) {
            // Update the existing change
            editedData[existingChangeIndex].newValue = value;
            editedData[existingChangeIndex].saved = false;
          } else {
            console.log("DATA PUSHED HERE TO EDIT DATA")
            // Add a new change
            editedData.push({
              rowId: updatedRowData.id,
              columnName: key as keyof TableDataItem,
              newValue: value,
              saved: false,
              isMultiplierChange: true,
            });
            
          }

          setEditedCells([...editedCells, column]);

          console.log("DATA PUSHED HERE TO STACK")
            const multiplierChange: Change = {
              id: updatedRowData.id, // Use a unique ID or identifier for multiplier changes
              columnName: key as keyof TableDataItem,
              originalValue: originalValue,
              newValue: value,
              saved: false,
              isMultiplierChange: true, // Mark the multiplier change as unsaved
            };
            // Push the change onto the stack
            setChangeStack((prevChangeStack) => [...prevChangeStack, multiplierChange]);

            // Get all the cell identifiers for the edited column
          const columns = updatedTableData.map((rowData) => `${rowData.id}-${key}`);
          setEditedCells((prevEditedCells) => [...prevEditedCells, ...columns]);
        }
      });
    });
    
    // Update the editedData state
    //setTableData(updatedTableData);
    setEditedData([...editedData]);
  };

  useEffect(() => {
    console.log("Yes, I'm here updatedEditedData useEffect");
    // Update the editedData based on filtered data
    if (!isResetting) {
      const updatedEditedData = editedData.filter(
        (change) => filteredTableData.some((item) => change.rowId === item.id) // Use rowId to match with the row's ID
      );
      setEditedData(updatedEditedData);
    }
  }, [filteredTableData]);

  useEffect(() => {
    try {
      //console.log("Yes,I'm here XML useEffect-> is RESET:",isResetting," XML DATA ", xmlData);
      if(!xmlData && isResetting){
        setIsResetting(false);
        //setIsLoading(false);
      }

      if (xmlData) {
        const result = xml2js.xml2json(xmlData, { compact: true, spaces: 2 });
        const parsedXml = JSON.parse(result);
        setXmlObject(parsedXml);

        // Extract and format data from 'parsedXml' as needed
        const extractedData = parseXmlData(parsedXml);
        const tableDataWithIds = extractedData.map((rowData, index) => ({
          ...rowData,
          id: index.toString(), // Assuming that the index is a unique identifier
        }));
        setOriginalData(tableDataWithIds);
        console.log(tableDataWithIds);
        setTableData(tableDataWithIds);
        setEditedData([]);
        setFilteredTableData(tableDataWithIds);
      }else{
        setXmlObject(null);
        setTableData([]);
      }
    } catch (error) {
      console.error("Error parsing XML:", error);
    } finally {
      setIsLoading(false);
    }
  }, [xmlData]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0, // Scroll to the top of the page
      behavior: "smooth", // Smooth scrolling animation
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleInputChange = (
    id: string,
    columnName: keyof TableDataItem,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsDirty(true);
    setIsCellBeingEdited(true);
    setIsMultiplierClicked(false);
    const newValue = event.target.value;
  
    // Find the row by its ID
    const rowData = filteredTableData.find((row) => row.id === id);
  
    if (rowData) {
      // Check if there's already a change for this cell in the stack
      const existingChangeIndex = changeStack.findIndex(
        (change) =>
          change.id === id && change.columnName === columnName
      );
  
      if (existingChangeIndex !== -1) {
        // Update the existing change in the stack
        changeStack[existingChangeIndex].newValue = newValue;
        changeStack[existingChangeIndex].saved = false; // Mark the change as unsaved
      } else {
        // Create an object representing the change
        const change = {
          id,
          columnName,
          originalValue: rowData[columnName],
          newValue,
          saved: false,
          isMultiplierChange:false, // Mark the change as unsaved
        };
  
        // Push the change onto the stack
        setChangeStack([...changeStack, change]);
      }
  
      updateData(id, columnName, newValue, false);
  
      if (!editedCells.includes(`${id}-${columnName}`)) {
        setEditedCells([...editedCells, `${id}-${columnName}`]);
      }
      setChangesSaved(false);
    }
  };

  const updateData = (
    id: string, // Use the row's ID
    columnName: keyof TableDataItem,
    newValue: string,
    isMultiplierChange:boolean
  ) => {
    // Create a copy of the current state
    const updatedData = [...filteredTableData];
  
    // Find the row by its ID
    const rowIndex = updatedData.findIndex((row) => row.id === id);
  
    if (rowIndex !== -1) {
      // Update the specific cell value
      updatedData[rowIndex][columnName] = newValue;
  
      // Update the editedData state
      const updatedEditedData = [...editedData];
      const existingChangeIndex = editedData.findIndex(
        (change) => change.rowId === id && change.columnName === columnName
      );
  
      if (existingChangeIndex !== -1) {
        // Update the existing change
        updatedEditedData[existingChangeIndex].newValue = newValue;
      } else {
        // Add a new change
        updatedEditedData.push({
          rowId: id,
          columnName,
          newValue,
          saved: false,
          isMultiplierChange
        });
      }
      // Update both filteredTableData and editedData
      setFilteredTableData(updatedData);
      setEditedData(updatedEditedData);
    }
  };

  const handleSaveClick = () => {
    setIsDirty(false);
    setIsMultiplierClicked(false);
    setIsCellBeingEdited(false);
    // Create a copy of the editedData to track saved changes
    const updatedEditedData = [...editedData];
    // Create a copy of the tableData to apply changes
    const updatedTableData = [...tableData];
    // Create a copy of the changeStack to track saved changes
    const savedChanges = [...changeStack];
    // Iterate through the editedData to apply changes and mark them as saved
    updatedEditedData.forEach(change => {

      if(change.isMultiplierChange && !change.saved){
        const newChange: Change = {
          id: change.rowId,
          columnName: change.columnName,
          originalValue: savedChanges.find((save) => save.id === change.rowId)?.originalValue ?? "1",
          newValue: change.newValue,
          saved: true,
          isMultiplierChange:true, // Mark the change as unsaved
        };
        setChangeStack((prevChangeStack) => [...prevChangeStack, newChange]);
        //updateData(change.rowId, change.columnName, change.newValue, change.isMultiplierChange);
      }else{
        const rowIndex = updatedTableData.findIndex(item => item.id === change.rowId);
        if (rowIndex !== -1) {
          updatedTableData[rowIndex][change.columnName] = change.newValue;
        }
        // Find the row by ID and update the corresponding column
        
        // Mark the change as saved
        change.saved = true;
        // Update the savedChanges
        const changeIndex = savedChanges.findIndex(
          savedChange =>
            savedChange.id === change.rowId && savedChange.columnName === change.columnName
        );
        if (changeIndex !== -1) {
          savedChanges[changeIndex].saved = true;
        }
        setChangeStack(savedChanges);
      }
      
    });
    // Update the editedData with the saved changes
    setEditedData(updatedEditedData);
    // Update the table data with the saved changes
    setTableData(updatedTableData);
    setFilteredTableData(updatedTableData);
    // Set the changeStack with saved changes

    //setChangeStack(savedChanges);

    const typesArray: any[] = [];
    // Helper function to split comma-separated values into separate elements
    const splitValues = (value: string) => {
      if (value) {
        return value.split(",").map((v) => v.trim());
      }
      return [];
    };
    console.log("TABLE DATA BEFORE GENERATING:",tableData)
    // Loop through the tableData and construct the XML structure
    tableData.forEach((item, index) => {
      const typeObject: any = {
        _attributes: { name: item.Name }, // Set the type name based on the NAME field
      };

      // Add properties to the typeObject
      if (item.Nominal) typeObject.nominal = { _text: item.Nominal };
      if (item.Lifetime) typeObject.lifetime = { _text: item.Lifetime };
      if (item.Restock) typeObject.restock = { _text: item.Restock };
      if (item.Min) typeObject.min = { _text: item.Min };
      if (item.QtMin) typeObject.quantmin = { _text: item.QtMin };
      if (item.QtMax) typeObject.quantmax = { _text: item.QtMax };
      if (item.Cost) typeObject.cost = { _text: item.Cost };

      const categoryValues = splitValues(item.Category);
      if (categoryValues.length > 0) {
        typeObject.category = categoryValues.map((category) => ({
          _attributes: { name: category },
        }));
      } else {
        typeObject.category = [];
      }

      // Split the "usage" field into separate entries
      const usageValues = splitValues(item.Usage);
      if (usageValues.length > 0) {
        typeObject.usage = usageValues.map((usage) => ({
          _attributes: { name: usage },
        }));
      } else {
        typeObject.usage = [];
      }

      // Split the "value" field into separate entries
      const valueValues = splitValues(item.Value);
      if (valueValues.length > 0) {
        typeObject.value = valueValues.map((value) => ({
          _attributes: { name: value },
        }));
      } else {
        typeObject.value = [];
      }
      // Push the typeObject to the typesArray
      typesArray.push(typeObject);
    });

    // Create an object for the XML header and typesArray
    const xmlData = {
      types: {
        type: typesArray, // Wrap all type objects in a single "type" element
      },
    };

    // Convert the object to XML
    const updatedXmlData = xml2js.js2xml(xmlData, {
      compact: true,
      spaces: 2,
    });
    setUpdatedXmlData(updatedXmlData);
    setEditedCells([]);
    setChangesSaved(true);
  };

  const saveChanges = (updatedXmlData: string) => {
    try {
      if(updatedXmlData){
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(updatedXmlData, "text/xml");
  
        // Find the corresponding XML element using row index
        const elements = Array.from(xmlDoc.getElementsByTagName("type"));
  
        elements.forEach((element) => {
          const costElement = element.getElementsByTagName('cost')[0];
          if (costElement) {
            const flagsElement = xmlDoc.createElement('flags');
            flagsElement.setAttribute('count_in_cargo', '0');
            flagsElement.setAttribute('count_in_hoarder', '0');
            flagsElement.setAttribute('count_in_map', '1');
            flagsElement.setAttribute('count_in_player', '0');
            flagsElement.setAttribute('crafted', '0');
            flagsElement.setAttribute('deloot', '0');
    
            // Insert the <flags> element after <cost>
            element.insertBefore(flagsElement, costElement.nextSibling);
    
            // Add a line break after the <flags> element
            element.insertBefore(xmlDoc.createTextNode('\n\t'), flagsElement);
          } else {
            console.error('Cost element not found.');
          }
        });
        return new XMLSerializer().serializeToString(xmlDoc);
      }else{
        setModalMessage("No changes detected to generate XML.");
        setShowModal(true);
        //alert("No changes saved detected to generate XML.")
        return null;
      }
      
    } catch (error) {
      console.error("Error updating XML:", error);
      return null; // Return the original data in case of an error
    }
  };

  const handleGenerateClick = () => {
    onUpdateGeneratedFiles();
    const data = saveChanges(updatedXmlData);

    if(data){
      // Add the declaration manually
      const finalXmlData = `<?xml version="1.0" encoding="UTF-8"?>\n${data}`;

      // Create a Blob with the updated XML data
      const blob = new Blob([finalXmlData], { type: "application/xml" });

      // Create a URL for the Blob and create a temporary link
      const url = URL.createObjectURL(blob);

      // Create an anchor element for downloading the XML file
      const a = document.createElement("a");
      a.href = url;
      a.download = "types.xml"; // Specify the filename here
      a.style.display = "none";

      // Add the anchor element to the DOM and trigger the download
      document.body.appendChild(a);
      a.click();

      // Clean up by revoking the URL object
      URL.revokeObjectURL(url);
      resetXmlEditor();
      setIsGenerationComplete(true);
      // Add a timeout to refresh the page after 2 seconds
      setTimeout(() => {
        localStorage.setItem('showCookiesPopup', 'false'); // Set to 'false' to hide the popup
        window.location.reload(); // Refresh the page
      }, 5000); // 2000 milliseconds (2 seconds)
    }
  };

  const handleUndoClick = () => {
    setIsDirty(false);
    setIsCellBeingEdited(false);
    setIsMultiplierClicked(false);
    console.log("UNDO EDITED DATA: ",editedData)
    console.log("UNDO Change Stack: ",changeStack)
    
    if (changeStack.length === 0) {
      return; // No changes to undo
    }
  
    // Find all the unsaved changes in the changeStack
    const unsavedChanges = changeStack.filter(change => !change.saved);
    console.log("UNDO Change Stack: ",changeStack)
    if (unsavedChanges.length === 0) {
      return; // No unsaved changes to undo
    }
  
    // Revert all unsaved changes
    unsavedChanges.forEach(change => {
      if(!change.isMultiplierChange){
        updateData(change.id, change.columnName, change.originalValue,change.isMultiplierChange);
      }
    });
  
    // Remove the unsaved changes from the stack
    setChangeStack(changeStack.filter(change => change.saved === true));
  
    // Update editedCells and changesSaved
    const updatedEditedCells = editedCells.filter(cell => !unsavedChanges.some(change => cell === `${change.id}-${change.columnName}`));
  
    setEditedCells(updatedEditedCells);
    setChangesSaved(false);
  };
 

  if (!xmlData && !tableData.length && isLoading) {
    // Modify this condition
    return (
      <div style={{ flex: 1 }}>
        <div className="loading-spinner">
          <Spinner animation="border" role="status">
            <span className="sr-only"></span>
          </Spinner>
        </div>
      </div>
    );
  }

  const handleColumnSort = (column: keyof TableDataItem) => {
    setSortOrder((prevSortOrder) => ({
      column,
      ascending: prevSortOrder.column === column ? !prevSortOrder.ascending : true,
    }));
  };

  const getSortedAndFilteredData = () => {
    // Create a copy of the original data
    const dataCopy = [...tableData];
    console.log("TABLE Data WHEN FILTERING:", tableData);
    // Apply filters
    const filteredData = dataCopy
      .filter((item) =>
        item.Name.toLowerCase().includes(nameFilter.toLowerCase())
      )
      .filter((item) => {
        if (categoryFilter) {
          return item.Category.includes(categoryFilter);
        }
        return true; // No filter applied for category
      })
      .filter((item) => {
        if (usageFilter) {
          return item.Usage.includes(usageFilter);
        }
        return true; // No filter applied for usage
      })
      .filter((item) => {
        if (valueFilter) {
          return item.Value.includes(valueFilter);
        }
        return true; // No filter applied for value
      });
  
      if (sortOrder.column !== null) {
        filteredData.sort((a, b) => {
          const columnAChange = editedData.find(
            (change) =>
              change.rowId === a.id && change.columnName === sortOrder.column
          );
          const columnA =
            (columnAChange && columnAChange.newValue !== undefined)
              ? parseFloat(columnAChange.newValue)
              : parseFloat(a[sortOrder.column as keyof TableDataItem]) || 0;
    
          const columnBChange = editedData.find(
            (change) =>
              change.rowId === b.id && change.columnName === sortOrder.column
          );
          const columnB =
            (columnBChange && columnBChange.newValue !== undefined)
              ? parseFloat(columnBChange.newValue)
              : parseFloat(b[sortOrder.column as keyof TableDataItem]) || 0;
    
          if (sortOrder.ascending) {
            if (sortOrder.column === 'Name' || sortOrder.column === 'Usage' || sortOrder.column === 'Value' || sortOrder.column === 'Category') {
              const aValues = a[sortOrder.column].split(', ');
              const bValues = b[sortOrder.column].split(', ');
    
              return aValues[0].localeCompare(bValues[0]);
            } else {
              return columnA - columnB; // Compare and return the difference
            }
          } else {
            if (sortOrder.column === 'Name' || sortOrder.column === 'Usage' || sortOrder.column === 'Value' || sortOrder.column === 'Category') {
              const aValues = a[sortOrder.column].split(', ');
              const bValues = b[sortOrder.column].split(', ');
    
              return bValues[0].localeCompare(aValues[0]);
            } else {
              return columnB - columnA; // Compare and return the difference
            }
          }
        });
    }
  
    // Log the filtered data and changes saved
    console.log("FILTERED DATA:", filteredData);
    console.log("changes saved:", changesSaved);
    console.log("Edited Data:", editedData);
    
  
    // Return the filtered and sorted data
    return filteredData;
  };  
  
  const rowHeight = 30;
  const cardHeight = xmlData ? "600px" : "100px"

  return (
    <div style={{ flex: 1 }}>
      {!xmlData && (
        <Form.Group className="mb-1">
          <Button
            variant="outline-primary"
            size="sm"
            onClick={onUploadTypesClick}
            style={{ fontWeight: "bold", width: "150px"}}
            disabled={unlockGenerateButton}
          >
            <FontAwesomeIcon className={"types-icons"} icon={faFileCode} /> Upload Types
          </Button>
          <input type="file" accept="text/xml" ref={xmlFileInputRef} style={{ display: "none" }} onChange={handleXmlFileUpload}/>
        </Form.Group>
      )}
      {!isResetting && tableData.length ? (
        <div style={{ height: cardHeight }}>
          <div className="filter-container">
            <input disabled={isDirty} className="filter-input" type="text" placeholder="Search by Name" onChange={(e) => setNameFilter(e.target.value)}
              style={{ marginTop: "5px", backgroundColor: isDarkMode ? "dimgrey" : "white" , }}/>
            <select disabled={isDirty} className="filter-dropdown" value={categoryFilter} onChange={(e) => setCategoryFilter(e.target.value)} style={{backgroundColor: isDarkMode ? "dimgrey" : "white" }}>
              <option value="">All Categories</option>
                {getUniqueValues("Category")
                  .filter((value) => value !== "")
                  .sort()
                  .map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                ))}
            </select>
            <select disabled={isDirty} className="filter-dropdown" value={usageFilter} onChange={(e) => setUsageFilter(e.target.value)} style={{backgroundColor: isDarkMode ? "dimgrey" : "white" }}>
              <option value="">All Usages</option>
                {getUniqueValues("Usage")
                  .filter((value) => value !== "")
                  .sort()
                  .map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                ))}
            </select>
            <select disabled={isDirty} className="filter-dropdown" value={valueFilter} onChange={(e) => setValueFilter(e.target.value)} style={{backgroundColor: isDarkMode ? "dimgrey" : "white" }}>
              <option value="">All Values</option>
              {getUniqueValues("Value")
                .filter((value) => value !== "")
                .sort()
                .map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
            </select>
            <div>
              <Button
                className="filter-button"
                variant="outline-secondary"
                onClick={clearFilters}
                disabled={isDirty}
                hidden={!nameFilter && !categoryFilter && !usageFilter && !valueFilter} >
                  <FontAwesomeIcon icon={faArrowsRotate} />
              </Button>
            </div>
            <div className="scroll-to-top-button" onClick={scrollToTop}>
              <i className="fa fa-arrow-up"></i>
            </div>
            <div className="scroll-to-bottom-button" onClick={scrollToBottom}>
              <i className="fa fa-arrow-down"></i>
            </div>
            <div className="buttons-container">
              
              <div className="button-group">
                <div className="label-and-buttons">
                  <strong style={{color: isDarkMode ? "white" : "black"}}>Multiplier</strong>
                  <div className="multiplier-button-wrapper">
                  <select value={selectedPercentage} className="filter-dropdown" onChange={handlePercentageChange} style={{backgroundColor: isDarkMode ? "dimgrey" : "white" }}>
                    {percentageOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  </div>
                </div>
              </div>
              <div className="button-group">
                <div className="label-and-buttons">
                  <strong style={{color: isDarkMode ? "white" : "black"}}>Nominal</strong>
                  <div className="multiplier-button-wrapper">
                    <Button className="multiplier" disabled={isCellBeingEdited} variant={isDarkMode ? 'primary' : 'outline-primary'} size="sm" onClick={() => handleMultiplierChange("Nominal", "down")}>
                      -
                    </Button>
                    <Button className="multiplier" disabled={isCellBeingEdited} variant={isDarkMode ? 'primary' : 'outline-primary'} size="sm" onClick={() => handleMultiplierChange("Nominal", "up")}>
                      +
                    </Button>
                  </div>
                </div>
              </div>
              <div className="button-group">
                <div className="label-and-buttons">
                  <strong style={{color: isDarkMode ? "white" : "black"}}>Min</strong>
                  <div className="multiplier-button-wrapper">
                    <Button className="multiplier" disabled={isCellBeingEdited} variant={isDarkMode ? 'primary' : 'outline-primary'} size="sm" onClick={() => handleMultiplierChange("Min", "down")}>
                      -
                    </Button>
                    <Button className="multiplier" disabled={isCellBeingEdited} variant={isDarkMode ? 'primary' : 'outline-primary'} size="sm" onClick={() => handleMultiplierChange("Min", "up")}>
                      +
                    </Button>
                  </div>
                </div>
              </div>
              <div className="button-group">
                <div className="label-and-buttons">
                  <strong style={{color: isDarkMode ? "white" : "black"}}>Lifetime</strong>
                  <div className="multiplier-button-wrapper">
                    <Button className="multiplier" disabled={isCellBeingEdited} variant={isDarkMode ? 'primary' : 'outline-primary'} size="sm" onClick={() => handleMultiplierChange("Lifetime", "down")}>
                      -
                    </Button>
                    <Button className="multiplier" disabled={isCellBeingEdited} variant={isDarkMode ? 'primary' : 'outline-primary'} size="sm" onClick={() => handleMultiplierChange("Lifetime", "up")}>
                      +
                    </Button>
                  </div>
                </div>
              </div>
              <div className="button-group">
                <div className="label-and-buttons">
                  <strong style={{color: isDarkMode ? "white" : "black"}}>Restock</strong>
                  <div className="multiplier-button-wrapper">
                    <Button className="multiplier" disabled={isCellBeingEdited} variant={isDarkMode ? 'primary' : 'outline-primary'} size="sm" onClick={() => handleMultiplierChange("Restock", "down")}>
                      -
                    </Button>
                    <Button className="multiplier" disabled={isCellBeingEdited} variant={isDarkMode ? 'primary' : 'outline-primary'} size="sm" onClick={() => handleMultiplierChange("Restock", "up")}>
                      +
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {isDirty && (<Button variant="success" size="sm" onClick={handleSaveClick} style={{ marginRight : "5px"}}>Save <FontAwesomeIcon icon={faFloppyDisk} /></Button>) }
            {isDirty && !isMultiplierClicked && (<Button variant="danger" size="sm" onClick={handleUndoClick} style={{ marginRight : "5px"}}>Discard <FontAwesomeIcon icon={faRotateLeft} /></Button>) }
            <Button hidden={isDirty} size="sm" variant={isDarkMode ? 'success' : 'outline-success'} onClick={handleGenerateClick}>Generate XML <FontAwesomeIcon style={{marginBottom:"2px"}} icon={faDownload} /></Button>
          </div>
          <br />
          {isLoading && !tableData.length && !isResetting ? (
            <div className="loading-spinner">
              <Spinner animation="border" role="status">
                <span className="sr-only"></span>
              </Spinner>
            </div>
          ) : (
            <AutoSizer>
              {({ height, width }) => (
                <div className="table-container">
                  <table className="header-table" style={{ width: width - 20 }}>
                    <thead>
                      <tr>
                        <th className="table-cell-name" onClick={() => handleColumnSort("Name")} style={{color: isDarkMode ? "white" : "black"}}>Name 
                        {sortOrder.column === "Name" && sortOrder.ascending ? (
                          <FontAwesomeIcon icon={faSortUp} />
                        ) : (
                          <FontAwesomeIcon icon={faSortDown} />
                        )}</th>
                        <th className="table-cell" onClick={() => handleColumnSort("Nominal")} style={{color: isDarkMode ? "white" : "black"}} >Nominal {sortOrder.column === "Nominal" && !sortOrder.ascending ? (
                          <FontAwesomeIcon icon={faSortUp} />
                        ) : (
                          <FontAwesomeIcon icon={faSortDown} />
                        )}</th>
                        <th className="table-cell" onClick={() => handleColumnSort("Min")} style={{color: isDarkMode ? "white" : "black"}}>Min {sortOrder.column === "Min" && !sortOrder.ascending ? (
                          <FontAwesomeIcon icon={faSortUp} />
                        ) : (
                          <FontAwesomeIcon icon={faSortDown} />
                        )}</th>
                        <th className="table-cell" onClick={() => handleColumnSort("Lifetime")} style={{color: isDarkMode ? "white" : "black"}}>Lifetime {sortOrder.column === "Lifetime" && !sortOrder.ascending ? (
                          <FontAwesomeIcon icon={faSortUp} />
                        ) : (
                          <FontAwesomeIcon icon={faSortDown} />
                        )}</th>
                        <th className="table-cell" onClick={() => handleColumnSort("Restock")} style={{color: isDarkMode ? "white" : "black"}}>Restock {sortOrder.column === "Restock" && !sortOrder.ascending ? (
                          <FontAwesomeIcon icon={faSortUp} />
                        ) : (
                          <FontAwesomeIcon icon={faSortDown} />
                        )}</th>
                        <th className="table-cell" onClick={() => handleColumnSort("QtMin")} style={{color: isDarkMode ? "white" : "black"}}>QtMin {sortOrder.column === "QtMin" && !sortOrder.ascending ? (
                          <FontAwesomeIcon icon={faSortUp} />
                        ) : (
                          <FontAwesomeIcon icon={faSortDown} />
                        )}</th>
                        <th className="table-cell" onClick={() => handleColumnSort("QtMax")} style={{color: isDarkMode ? "white" : "black"}}>QtMax {sortOrder.column === "QtMax" && !sortOrder.ascending ? (
                          <FontAwesomeIcon icon={faSortUp} />
                        ) : (
                          <FontAwesomeIcon icon={faSortDown} />
                        )}</th>
                        <th className="table-cell" onClick={() => handleColumnSort("Cost")} style={{color: isDarkMode ? "white" : "black"}}>Cost {sortOrder.column === "Cost" && sortOrder.ascending ? (
                          <FontAwesomeIcon icon={faSortUp} />
                        ) : (
                          <FontAwesomeIcon icon={faSortDown} />
                        )}</th>
                        <th className="table-cell-wider" onClick={() => handleColumnSort("Category")} style={{color: isDarkMode ? "white" : "black"}}>Category {sortOrder.column === "Category" && sortOrder.ascending ? (
                          <FontAwesomeIcon icon={faSortUp} />
                        ) : (
                          <FontAwesomeIcon icon={faSortDown} />
                        )}</th>
                        <th className="table-cell-wider" onClick={() => handleColumnSort("Usage")} style={{color: isDarkMode ? "white" : "black"}}>Usage {sortOrder.column === "Usage" && sortOrder.ascending ? (
                          <FontAwesomeIcon icon={faSortUp} />
                        ) : (
                          <FontAwesomeIcon icon={faSortDown} />
                        )}</th>
                        <th className="table-cell-wider" onClick={() => handleColumnSort("Value")} style={{color: isDarkMode ? "white" : "black"}}> Value{sortOrder.column === "Value" && sortOrder.ascending ? (
                          <FontAwesomeIcon icon={faSortUp} />
                        ) : (
                          <FontAwesomeIcon icon={faSortDown} />
                        )}</th>
                      </tr>
                    </thead>
                  </table>
                  <div className="data-table-container">
                    <List
                      width={width}
                      height={height - rowHeight}
                      rowCount={tableData.length}
                      rowHeight={rowHeight}
                      rowRenderer={({ key, index, style }) => {
                        const sortedAndFilteredData = getSortedAndFilteredData();
                        // Check if the index is valid before accessing filteredRowData
                        if (index >= 0 && index < sortedAndFilteredData.length) {
                          const rowData = sortedAndFilteredData[index];

                          // Use the rowData for rendering
                          return (
                            <div key={key} style={style}>
                              <table className="data-table" style={{width:"inherit"}}>
                                {/* Render your editable row here */}
                                <tbody>
                                  <tr key={index}>
                                    {Object.entries(rowData)
                                      .filter(([key]) => key !== "id")
                                      .map(([key, value], columnIndex) => (
                                        <td
                                          key={columnIndex}
                                          className={
                                            key === "Name"
                                              ? "table-cell-name"
                                              : key === "Nominal" ||
                                                key === "Min" ||
                                                key === "Lifetime" ||
                                                key === "Restock" ||
                                                key === "QtMin" ||
                                                key === "QtMax" ||
                                                key === "Cost"
                                              ? "table-cell"
                                              : "table-cell-wider"
                                          }
                                        >
                                          <input
                                            type="text"
                                            name={key}
                                            disabled={isMultiplierClicked}
                                            value={
                                              (
                                                editedData.find(
                                                  (change) => change.rowId === rowData.id && change.columnName === key && !change.saved) || { newValue: value }
                                              ).newValue
                                            }
                                            className={"input-cell"}
                                            style={{
                                              background: !changesSaved && editedCells.includes(`${rowData.id}-${key}`)
                                                ? "lightgreen" // Background color for pending changes
                                                : changesSaved
                                                ? isDarkMode
                                                  ? "dimgrey" // Change to dimgrey in dark mode when changes are saved
                                                  : "white" // Reset the background to the default style when changes are saved
                                                : isDarkMode
                                                ? "dimgrey" // Change to dimgrey in dark mode for default background
                                                : "white" // Default background color
                                            }}
                                            
                                            onChange={(event) =>
                                              handleInputChange(rowData.id, key as keyof TableDataItem, event)
                                            }
                                          />
                                        </td>
                                      ))}
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          );
                        } else {
                          return null; // Return null for invalid index
                        }
                      }}
                    />
                  </div>
                </div>
              )}
            </AutoSizer>
          )}
        </div>
      ) : (
        isGenerationComplete && (
          <h5 style={{ color: "green" }}>File Generated! Load another file...</h5>
        )
    )}
    <CustomModal show={showModal} message={modalMessage} handleClose={handleCloseModal} />
    </div>
  );
}

export default XmlTableEditor;
