import React from "react";
import {
  Button,
  Modal
} from "react-bootstrap";

interface  PopupCookiesDialogProps {
  onClose: () => void;
}

function PopupCookiesDialog({ onClose }: PopupCookiesDialogProps) {
  return (
    <Modal show={true} onHide={onClose} centered style={{paddingTop: "15%"}}>
      <Modal.Header closeButton>
        <Modal.Title>Privacy Policy</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <h2>Privacy Policy</h2>
      <p>
        We are committed to respecting your privacy and complying with the
        General Data Protection Regulation (GDPR). We do not utilize cookies or
        collect any personal data through this website.
      </p>

      <h2>Cookies Policy</h2>
      <p>
        In accordance with the General Data Protection Regulation (GDPR), this
        website does not use cookies for any purpose. No information is stored
        on your device during your visit to this website. Your privacy is
        important to us, and we strive to maintain it in full compliance with
        GDPR regulations.
      </p>

      <h2>End-User License Agreement</h2>
      <ol>
        <li>
          <strong>License Grant:</strong>
          <p>
            Our modding projects and any associated materials are provided under
            a limited, revocable, non-transferable license to use, modify, and
            integrate them into the specified game for personal, non-commercial
            purposes only. We retain all ownership and intellectual property
            rights in and to the modding projects.
          </p>
        </li>
        <li>
          <strong>Restrictions:</strong>
          <p>
            The user may not sell, sublicense, lease, the modding project to any
            third party or use the modding projects for any commercial purposes
            or in a way that violates any applicable laws or regulations.
          </p>
        </li>
        <li>
          <strong>Intellectual Property:</strong>
          <p>
            All intellectual property rights related to the modding projects,
            including but not limited to copyrights, trademarks, and trade
            secrets, are owned by the Developer. The User shall not claim any
            ownership or rights to the modding projects except as expressly
            granted in this EULA.
          </p>
        </li>
        <li>
          <strong>Modifications and Updates:</strong>
          <p>
            We grant permission for modification solely for personal,
            non-commercial use, allowing users to adapt the modding projects to
            suit their individual needs. However, please be aware that modifying
            the project in this manner forfeits any entitlement to future
            updates and absolves us of responsibility for resulting malfunctions
            or issues.
          </p>
        </li>
        <li>
          <strong>Limitation of Liability:</strong>
          <p>
            In no event shall we be liable for any direct, indirect,
            incidental, special, consequential, or punitive damages, including
            but not limited to damages for loss of profits, goodwill, use, data,
            or other intangible losses resulting from the use or inability to
            use our modding projects.
          </p>
        </li>
        <li>
          <strong>Termination:</strong>
          <p>
            This license is effective until terminated by the Developer. The
            Developer may terminate this license at any time without notice if
            the User fails to comply with the terms of this EULA.
          </p>
        </li>
        <li>
          <strong>Governing Law:</strong>
          <p>
            This EULA shall be governed by and construed in accordance with the
            laws of France.
          </p>
        </li>
        <li>
          <strong>Contact:</strong>
          <p>
            If you have any questions or concerns about this EULA, please
            contact us at <a href="https://discord.com/invite/3pb8QcMrG5" target="_blank" >Support</a>
          </p>
        </li>
      </ol>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );

}

export default PopupCookiesDialog;
