import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines, faFileCode} from "@fortawesome/free-solid-svg-icons";


interface TutorialModalProps {
  show: boolean;
  onHide: () => void;
}

const TutorialModal: React.FC<TutorialModalProps> = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>How to use DzTools ?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <Button variant="outline-primary" size="sm" style={{marginLeft: "3%",marginTop: "0", fontWeight: "bold"}}>
            <FontAwesomeIcon className={"types-icons"} icon={faFileLines} />Upload
          </Button>
          <strong> Click "Upload" to upload your .txt file with your classnames and generate a type.xml.</strong>
        </p>
        <p>
          <Button variant="outline-primary" size="sm" style={{marginLeft: "3%",marginTop: "0" , fontWeight: "bold"}}>
            <FontAwesomeIcon className={"types-icons"} icon={faFileCode} />Upload Types
          </Button>
          <strong> Click "Upload Types" to upload your types.xml file and edit it as you wish.</strong>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TutorialModal;
