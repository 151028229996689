import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { AutoSizer, List } from 'react-virtualized';
import "../css/FeedbackMessage.css";
import StarRatingComponent from 'react-star-rating-component';
import {DZTOOLS_URL} from "./config/Config"


interface Message {
  type: string;
  username: string;
  message: string;
  creationDate: Date;
  rating:number;
}

function FeedbackMessages() {
  const [messages, setMessages] = useState<Message[]>([]);

  useEffect(() => {
    // Fetch users data from the server when the component mounts
    const fetchMessages = async () => {
      try {
        const response = await axios.get(DZTOOLS_URL + "/messages/feedback");
    
        if (response.status === 200) {
            setMessages(response.data);
        }
        console.log(messages);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();
  }, []);

  function formatDate(isoDate:Date){
    const date = new Date(isoDate);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
  }

  const rowRenderer = ({ index, key, style }: { index: number; key: string; style: React.CSSProperties }) => {
    const message = messages[index];
  
    return (
      <div key={key} className="message-container" style={{ ...style, marginBottom: "5%" }}>
        <div className="username">
          <strong>Username: </strong> {message.username}   <strong> : </strong>    {formatDate(message.creationDate)}
        </div>
        <div className="rating">
          <StarRatingComponent
            name={`rating-${index}`} // Use a unique name for each rating
            starCount={5}
            value={message.rating}
            editing={false} // Disable user interaction for ratings
          />
        </div>
        <div className="message">{message.message}</div>
      </div>
    );
  };
  
  const rowHeight = 125;
  const cardHeight = "550px";

  return (
    <div style={{height: cardHeight , width: "650px"}}>
      {messages.length === 0 ? (
        <span>No feedback provided yet :)</span>
      ) : (
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height - rowHeight}
              width={width}
              rowCount={messages.length}
              rowHeight={rowHeight} // Adjust this value based on your design
              rowRenderer={rowRenderer}
            />
          )}
        </AutoSizer>
      )}
    </div>
  );
}

export default FeedbackMessages;
