"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DZTOOLS_URL = exports.DZTOOLS_DOM = exports.HOST_IP_ADDRESS = exports.PORT = exports.CLIENT_ID = exports.DEV_MODE = void 0;
exports.DEV_MODE = false;
exports.CLIENT_ID = exports.DEV_MODE ? "Ab5nc9P9sFhUCr6dzS4Bh7E9Xwq21gL7yMS1B43XA4WvxmxOqVjoewpzNYfwtdUutYauRgkG9E-6PE8d" : "AVIXSUL__TPYpgJFNJXzCQGuUedDbECXfSSwIOyVyR_aGCYO1ilp-0sJB4UFuQgCemjI4pRHvue23vVp";
//export const CLIENT_ID = "Ab5nc9P9sFhUCr6dzS4Bh7E9Xwq21gL7yMS1B43XA4WvxmxOqVjoewpzNYfwtdUutYauRgkG9E-6PE8d" //SANDBOX
//export const DZTOOLS_URL = "http://localhost:5050"
//export const DZTOOLS_URL = "http://localhost:5050"
exports.PORT = 5050;
exports.HOST_IP_ADDRESS = "77.192.247.110";
exports.DZTOOLS_DOM = "https://dztools.online:5050/";
exports.DZTOOLS_URL = exports.DEV_MODE ? "http://localhost:5050" : "https://dztools.online:5051";
