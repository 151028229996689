import React, { useState } from "react";
import { PayPalButton } from "react-paypal-button-v2";
import {Modal, Button, Card} from "react-bootstrap";
import {CLIENT_ID} from "../config/Config"
import axios from 'axios';
import {DZTOOLS_URL} from "../config/Config"
import "../../App.css";

interface SubscriptionProps{
  showModal: boolean;
  closeModal: () => void;
  username: string | null;
}

const Subscription: React.FC<SubscriptionProps> = ({ showModal,closeModal,username }) => {
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState<string>("");

  const onSuccess = async (details: any, data: any) => {
    // The payment was successful. You can handle success here.
    setPaymentSuccess(true);
    console.log("Payment success!", details, data);
    const originalDate = new Date(details.create_time);
    const amount = details.purchase_units[0].amount.value;
    try {
      await axios.put(DZTOOLS_URL + "/user-subscription",  { username:username, donations:amount , subscriptionDate:originalDate} ); // Replace with your actual API endpoint
      // Update the user's generatedFiles count in your component state
      } catch (error) {
      console.error('Error updating generatedFiles count:', error);
    }

  };

  const onError = (err: any) => {
    // An error occurred during the payment. You can handle errors here.
    console.error("Payment error", err);
  };

  const handleSelectSubscriptionPlan = (plan: string) => {
    setSelectedSubscriptionPlan(plan);
  };

  const isCardSelected = (plan: string) => {
    return plan === selectedSubscriptionPlan;
  };

  return (
    <div>
      <Modal show={showModal} onHide={closeModal} dialogClassName="subscription-modal">
        <Modal.Header closeButton>
          <Modal.Title>
            Subscription renewal for "<label>{username}</label>"
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!paymentSuccess ? (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <Card
                    className={
                      isCardSelected("1.99") ? "selected-card" : "subscription-card"
                    }
                  >
                    <Card.Body>
                      <Card.Title>1 Month Subscription</Card.Title>
                      <Card.Text>Price: 1.99€</Card.Text>
                      {isCardSelected("1.99") ? (
                        <Button variant="success">Selected</Button>
                      ) : (
                        <Button
                          variant="primary"
                          onClick={() => handleSelectSubscriptionPlan("1.99")}
                        >
                          Select
                        </Button>
                      )}
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-4">
                  <Card
                    className={
                      isCardSelected("4.99") ? "selected-card" : "subscription-card"
                    }
                  >
                    <Card.Body>
                      <Card.Title>3 Months Subscription</Card.Title>
                      <Card.Text>Price: 4.99€</Card.Text>
                      {isCardSelected("4.99") ? (
                        <Button variant="success">Selected</Button>
                      ) : (
                        <Button
                          variant="primary"
                          onClick={() => handleSelectSubscriptionPlan("4.99")}
                        >
                          Select
                        </Button>
                      )}
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-4">
                  <Card
                    className={
                      isCardSelected("8.99") ? "selected-card" : "subscription-card"
                    }
                  >
                    <Card.Body>
                      <Card.Title>6 Months Subscription</Card.Title>
                      <Card.Text>Price: 8.99€</Card.Text>
                      {isCardSelected("8.99") ? (
                        <Button variant="success">Selected</Button>
                      ) : (
                        <Button
                          variant="primary"
                          onClick={() => handleSelectSubscriptionPlan("8.99")}
                        >
                          Select
                        </Button>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <br />
              {selectedSubscriptionPlan && (
                <PayPalButton
                  amount={selectedSubscriptionPlan} // Set the payment amount based on the selected plan
                  onSuccess={onSuccess}
                  onError={onError}
                  options={{
                    clientId: CLIENT_ID,
                    currency: "EUR",
                    intent: "capture", // or 'authorize'
                  }}
                />
              )}
            </div>
          ) : (
            <div>
              <strong style={{color:"green"}}>Payment was successful!</strong>
              {/* You can display a confirmation message or redirect the user here */}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Subscription;
