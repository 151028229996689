import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

interface TextUploadModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
  textAreaContent: string;
  onTextAreaChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  isDarkMode: boolean;
}

const TextUploadModal: React.FC<TextUploadModalProps> = ({
  show,
  onClose,
  onSubmit,
  textAreaContent,
  onTextAreaChange,
  isDarkMode
}) => (
    
  <Modal show={show} onHide={onClose}>
    <Modal.Header className="text-card-header" style={{ backgroundColor: isDarkMode ? '#333' : '#fff' }} closeButton>
      <FontAwesomeIcon style={{ fontSize: "20", marginRight: "5px", color: isDarkMode ? "white" : "black"}} className={"types-icons"} icon={faFileLines} />
      <Modal.Title style={{ color: isDarkMode ? "white" : "black"}}>Classnames</Modal.Title> 
    </Modal.Header>
    <Modal.Body style={{ backgroundColor: isDarkMode ? '#333' : '#fff' }}>
      <Form.Control
        as="textarea"
        rows={5}
        placeholder="Paste classnames here..."
        value={textAreaContent}
        onChange={onTextAreaChange}
      />
      <div style={{marginTop: "4%", color: isDarkMode ? "white" : "black"}}>
          <strong>Upon submitting the classnames, please assign values for Category, Value, and Usage according to your needs.</strong>
      </div>
    </Modal.Body>
    <Modal.Footer style={{ backgroundColor: isDarkMode ? '#333' : '#fff' }}>
      <Button variant="secondary" size="sm" onClick={onClose}>
        Close
      </Button>
      <Button variant="primary" size="sm" onClick={onSubmit}>
        Submit
      </Button>
    </Modal.Footer>
  </Modal>
);

export default TextUploadModal;
