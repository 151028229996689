import React,{useState} from "react";
import "../../css/PopupDialog.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import Subscription from "./Subscription"; 
import {Button} from "react-bootstrap";

interface PopupDialogProps {
  onClose: () => void;
  daysLeft: number | null;
  username: string | null;
}
interface MessageProps {
  daysLeft: number | null;
}

const Message: React.FC<MessageProps> = ({ daysLeft }) => {
  const subsEndDate = daysLeft !== null ? daysLeft.toString() : null; // Convert daysLeft to string if it's not null

  // Define a CSS class based on the value of daysLeft
  const messageClass = daysLeft !== null && daysLeft <= 3 ? "message-red" : "";

  return (
    <div>
      {subsEndDate && daysLeft && daysLeft <= 3 && (
        <div className={`message ${messageClass}`}>
          <>
            <FontAwesomeIcon icon={faExclamationCircle} className="message-icon" />
            YOUR SUBSCRIPTION ENDS IN: {subsEndDate} DAYS !
            <FontAwesomeIcon icon={faExclamationCircle} className="message-icon" />
          </>
        </div>
      )}
    </div>
  );
};

const PopupDialog: React.FC<PopupDialogProps> = ({ onClose, daysLeft, username }) => {
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const handleClick = () => {
    console.log("I'm here at handleClick SUBSCRIPTION")
    setShowSubscriptionModal(true);
  };

  return (
    <div>
      {daysLeft !== null && daysLeft <= 3 && (
        <div className="popup-dialog">
          <div className="popup-content">
            <Message daysLeft={daysLeft} />
            <Button variant="outline-primary" className="popup-button-ok" onClick={onClose}>
              OK
            </Button>
            <Button variant="success" className="popup-button-donate" onClick={handleClick}>
              Renew
            </Button>
          </div>
        </div>
      )}
      {showSubscriptionModal && (
        <Subscription
          showModal={showSubscriptionModal}
          closeModal={() => setShowSubscriptionModal(false)}
          username={username || ''}
        />
      )}
    </div>
  );
};

export default PopupDialog;
