import { handleLines } from "./utils/XmlUtils";

function XMLGenerator(file: any, formData: FormData, newCheckboxData: string[]) {
  console.log("IM HERE DUUUUUUUUUDE !");
  console.log(file);
  const checkedCheckboxes: never[] = [];
  let lines: string[] = [];

  if (file) {
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      if (event.target && event.target.result) {
        const contents = event.target.result as string;

        // Extract text within double quotes or on separate lines
        const regex = /"([^"]+)"|([^\r\n]+)/g;
        const matches = contents.match(regex);

        if (matches) {
          lines = matches.map(match => match.replace(/"/g, '').trim()).filter(line => line !== '');
          console.log(lines);
          handleLines(lines, formData, checkedCheckboxes, newCheckboxData);
        }
      }
    };
    fileReader.readAsText(file);
  }

  const xmlString = "xmlString"; // Replace this with the actual XML string

  return { xmlString, checkedCheckboxes };
}

export default XMLGenerator;
