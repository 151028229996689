import React, { useState, useRef,ChangeEvent } from "react";
import axios from 'axios';
import { Modal, Button, Form } from "react-bootstrap";
import CustomPasswordInput from "../CustomPasswordInput"; 
import {DZTOOLS_URL} from "../config/Config"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle , faTimesCircle } from "@fortawesome/free-solid-svg-icons";

interface PasswordRecoveryModalProps {
    show: boolean;
    onClose: () => void; // Define the onClose prop
  }
function PasswordRecoveryModal({ show, onClose }: PasswordRecoveryModalProps){
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const [username, setUsername] = useState("");
  const [usernameExists, setUsernameExists] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [validAnswer, setValidAnswer] = useState(false);
  const [validToken, setValidToken] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const passwordRegex = /^(?=.*[0-9])(?=.*[!@#\$%^&*])(?=.*[A-Za-z])[A-Za-z0-9!@#\$%^&*]{8,}$/;
  const [validPassword, setValidPassword] = useState(false);

  // Function to check if the username exists and retrieve the security question
  const checkUsername = async () => {
    try {
        const response = await axios.get(DZTOOLS_URL + "/check-username?username=" + username);
        if (response.data.exists) {
            setUsernameExists(true)
            setQuestion(response.data.question)
        } else {
            alert("Username dos not exist")
            setUsernameExists(false);
            setQuestion("");
        }
      } catch (error) {
        console.error("Error checking username:", error);
        throw error; 
      }
  };

  // Function to handle the submission of the answer and token
  const handleSubmitAnswer = async () => {
    try {
      const response = await axios.get(DZTOOLS_URL + "/check-answer?username="+ username+"&answer=" + answer);

      if (response.data.correctAnswer) {
          setValidAnswer(true);
      } else {
          alert("wrong answer");
          setValidAnswer(false);
          setAnswer("");
      }
    } catch (error) {
      console.error("Error checking username:", error);
      throw error; 
    }
  }

  const handleTokenSubmit = async () => {
    try {
        const response = await axios.get(DZTOOLS_URL + "/check-token?username="+username+"&token="+token);
  
        if (response.data.correctToken) {
            setValidToken(true);
        } else {
            alert("wrong token");
            setValidToken(false);
            setToken("");
        }
      } catch (error) {
        console.error("Error checking username:", error);
        throw error; 
      }
  };

  const handlePasswordSubmit = async () => {
    try {
      // Update the password state and wait for the state update
      //const newPassword = await setPassword(password);
      const authToken = localStorage.getItem('authToken');
      if(isPasswordValid(password)) {
        setValidPassword(true) 
        // Make an API request to update the user's password
        const response = await axios.put(DZTOOLS_URL + "/password-update", {
          username: username,
          password: password,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
    
        if (response.status === 200) {
          alert("Password updated successfully!");
          window.location.reload();
        } else {
          console.error("Error updating password");
        }
      }else{
        setValidPassword(false);
        setErrorMessage("")
      }
      
    } catch (error) {
      console.error("Error updating password:", error);
    }
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    if(isPasswordValid(event.target.value)) {
      setValidPassword(true);
      setPassword(event.target.value);
    }else{
      setValidPassword(false);
    }
  };

  const isPasswordValid = (password:string) => {
    return passwordRegex.test(password);
  };


  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Password Recovery</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
        <Form.Group controlId="username">
        <Form.Label>Username</Form.Label>
        <div className="d-flex">
          <Form.Control
            type="text"
            placeholder="Enter your username"
            value={username}
            disabled={username !== "" && usernameExists}
            onChange={(e) => setUsername(e.target.value)}
            maxLength={12}
          />
          <Button
            variant="primary"
            onClick={checkUsername}
            hidden={usernameExists}
          >
            Check
          </Button>
        </div>
      </Form.Group>
          {usernameExists && (
            <div style={{ marginTop: "5%"}}>
              <Form.Group>
                <Form.Label>{question}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your answer"
                  value={answer}
                  onChange={(e) => setAnswer(e.target.value)}
                  disabled={validAnswer}
                />
              </Form.Group>
              <Button variant="primary" onClick={handleSubmitAnswer} hidden={validAnswer} style={{ marginTop: "2%"}}>
                Submit Answer
              </Button>
              { validAnswer && (
                <Form.Group style={{ marginTop: "5%"}}>
                <Form.Label>Token</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your token"
                  value={token}
                  onChange={(e) => setToken(e.target.value)}
                  disabled={validToken}
                />
              </Form.Group>
              )}
              
              <Button variant="primary" onClick={handleTokenSubmit} hidden={!validAnswer || validToken} style={{ marginTop: "2%"}}>
                Submit Q
              </Button>
              {validToken && (
                <div style={{ marginTop: "5%"}}>
                  <Form.Group>
                    <Form.Label>New Password</Form.Label>
                    <CustomPasswordInput
                      showPassword={showPassword}
                      onTogglePassword={() => setShowPassword(!showPassword)}
                      ref={passwordRef}
                      onChange={handlePasswordChange}
                      maxLength={20}
                    />
                  </Form.Group>
                  {password !=="" && !validPassword && (
                    <p style={{color: "red"}}>Password must have between 8 and 20 characters, contain a number and a special character. <FontAwesomeIcon icon={faTimesCircle} color="red" /></p>
                  )}
                  {validPassword && (
                    <p style={{color: "green"}}>Valid Password! <FontAwesomeIcon icon={faCheckCircle} color="green" /></p>
                  )}
                  <Button variant="primary" onClick={handlePasswordSubmit} style={{ marginTop: "2%"}} disabled={!validPassword}>
                    Change Password
                  </Button>
                </div>
              )}
            </div>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default PasswordRecoveryModal;
