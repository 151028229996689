import ItemLists from "../classes/ItemLists";
import { create } from "xmlbuilder2";

export function handleLines(lines: string[], formData: any, checkedCheckboxes: any, newCheckboxData: string[]) {
    for (const [key, value] of formData.entries()) {
        if (value === "on") {
            checkedCheckboxes.push(key);
        }
    }

    console.log(checkedCheckboxes);

    const root = create({ version: "1.0", encoding: "UTF-8" }).ele("types");

    if (lines) {
        const filteredLines = lines.filter(item => !/[\\[\]]/.test(item)); // Filter out items with "[" or "]"

        for (const item of filteredLines) {
          const itemNameWithoutComma = item.replace(/,\s*$/, ""); // Remove trailing comma
          const rootElement = root.ele("type").att("name", itemNameWithoutComma)
                .ele("nominal").txt(String(5)).up()
                .ele("lifetime").txt(String(14400)).up()
                .ele("restock").txt(String(1800)).up()
                .ele("min").txt(String(2)).up()
                .ele("quantmin").txt(String(-1)).up()
                .ele("quantmax").txt(String(-1)).up()
                .ele("cost").txt(String(100)).up()
                .ele("flags").att("count_in_cargo", "0").att("count_in_hoarder", "0").att("count_in_map", "1").att("count_in_player", "0").att("crafted", "0").att("deloot", "0").up();
            handleItem(item, rootElement, checkedCheckboxes, newCheckboxData);
        }
    }
    const xmlString = root.end({ prettyPrint: true });
    const xmlBlob = new Blob([xmlString], { type: "application/xml" });
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(xmlBlob);
    downloadLink.download = "types.xml";
    downloadLink.click();
}


function handleItem(item: any, rootElement: any, checkedCheckboxes:any, newCheckboxData: string[]){
    const itemList = new ItemLists(12);
  
    for(const checkbox of checkedCheckboxes){
      if(itemList.categoryList.includes(checkbox)){
        rootElement.ele("category").att("name", checkbox).up();
      }
      if(itemList.usageList.includes(checkbox) || newCheckboxData.includes(checkbox)){
        rootElement.ele("usage").att("name", checkbox).up();
      }
      if(itemList.valueList.includes(checkbox)){
        rootElement.ele("value").att("name", checkbox).up();
      }
    }
    
  }