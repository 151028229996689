import React, { useState, useRef, useEffect, ChangeEvent } from "react";
import "../css/Login.css";
import {
    Button,
    Card,
    Form,
} from "react-bootstrap";
import axios from 'axios';
import AuthenticationService from "../backend/services/AuthenticationService";
import RegisterModal from "./modals/RegisterModal";
import Subscription from "./modals/Subscription"; 
import FeedbackMessages from "./FeedbackMessages"; 
import CustomPasswordInput from "./CustomPasswordInput"; 
import PasswordRecoveryModal from "./modals/PasswordRecoveryModal"; 
import {DZTOOLS_URL} from "./config/Config"
import logo from '../resources/DayZ_Logo.jpg';

interface User {
  username: string;
  role: string;
  subsEndDate: string;
  generatedFiles: number;
  token: string;
}

interface LoginProps {
  onLogin: (user: User) => void;
}

function Login({ onLogin }: LoginProps){ 

  const usernameRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
   // Add a state variable to manage the visibility of the subscription modal
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [usernameLogin, setUsernameLogin] = useState<string | null>(null);
  const [showPasswordRecoveryModal, setShowPasswordRecoveryModal] = useState(false);
  const [isRememberMe, setRememberMe] = useState(false);
  const [totalFilesGenerated, setTotalFilesGenerated] = useState<string | null>(null);


  useEffect(() => {
    const rememberMe = localStorage.getItem("rememberMe");
    const savedUsername = localStorage.getItem("username");
  
    if (rememberMe === "true" && savedUsername) {
      setRememberMe(true);
      if (usernameRef.current) {
        usernameRef.current.value = savedUsername;
      }
    }
    getTotalFiles()
  }, []);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const username = usernameRef.current ? usernameRef.current.value : '';
      const password = passwordRef.current ? passwordRef.current.value : '';
      if (isRememberMe) {
        // Save the username in localStorage
        localStorage.setItem("username", username);
      }
      setUsernameLogin(username);
      console.log("Trying to LOGIN");
      const response = await axios.post(DZTOOLS_URL + "/login", { username, password });
  
      if (response.status === 200) {
        // Login successful, you can redirect to a different page or update the UI accordingly
        const user = response.data;
        console.log(user);
        localStorage.setItem('justLoggedin', 'true');
        const now = new Date();
        const subsEndDate = new Date(user.subsEndDate);

        if(subsEndDate.getTime() < now.getTime()){
          //alert("INVALID SUBSCRIPTION")
          throw new Error("Subscription")
        }
        alert('Login successful');

        AuthenticationService.setUser(user);
        onLogin(user);
        console.log('isAuthenticated:', AuthenticationService.getIsAuthenticated());
        console.log('Role:', user.role);
      }
    } catch (error) {
      console.error('Error during login:', error);
      if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
        setErrorMessage("Login failed. Invalid username or password.");
      }else if(error instanceof Error && error.message === "Subscription"){
        setErrorMessage("Outdated Subscription! Subscribe to use the site!");
        setTimeout(() => {
          setShowSubscriptionModal(true);
        }, 1000);
      } else {
        setErrorMessage("Login failed. Please try again.");
      }
    }
  };

  const getTotalFiles = async () => {
    try{
      const response = await axios.get(DZTOOLS_URL + "/check-total-files");
      if (response.status === 200) {
        // Update the user list with the new data
        setTotalFilesGenerated(response.data);
      } else {
        console.error("Error fetching total files:", response.data);
      }
    }catch(error){

    }
    
  };

  const toggleRegistrationModal = () => {
    setShowRegistration(!showRegistration);
  };

  const openPasswordRecoveryModal = () => {
    setShowPasswordRecoveryModal(true);
  };

  const handleRememberMeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setRememberMe(newValue);

    localStorage.setItem('rememberMe', newValue.toString());
  };
  
  return (
    <div className="mainContainer fixed-background login-container"  style={{ minHeight: "100vh", display: "flex", flexDirection: "column" , paddingBottom: "300px" }}>
        <div>
          <Card className="admin-card" style={{marginTop: "35px"}}>
            <Card.Header><h4>Number of files generated: {totalFilesGenerated}</h4></Card.Header>
          </Card>
        </div>
        <Card className="login-card" style={{marginTop: "2%"}}>
            <Card.Header style={{justifyContent: "space-between"}}>
              <img style={{ marginLeft: "1%" , marginBottom:"1.5%"}} src={logo} alt="DZTOOLS Logo" width="30" height="30" className="d-inline-block align-top blinking" />
              <h2 className="blinking">Welcome to DZTOOLS!</h2>
              </Card.Header>
            <Card.Body>
              <Form>
                <Form.Group className="mb-3" controlId="username">
                  <Form.Control type="text" placeholder="Username" className="input-border-radius" ref={usernameRef} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="password">
                  <CustomPasswordInput
                    showPassword={showPassword}
                    onTogglePassword={() => setShowPassword(!showPassword)}
                    ref={passwordRef}
                  />
                </Form.Group>
                {errorMessage && (<div className="text-danger mb-3">{errorMessage}</div>)}
                <Form.Group className="mb-3" controlId="rememberMe" style={{ textAlign: 'left' }} >
                <Form.Check
                  type="checkbox"
                  label="Remember me!"
                  onChange={handleRememberMeChange}
                  checked={isRememberMe}
                />
                </Form.Group>
                <Button variant="primary" type="submit" className="button-login" onClick={(e) => handleLogin(e)}>Login</Button>
                <Button variant="outline-success" className="button-register" onClick={toggleRegistrationModal}>Register</Button>
                <div
                  style={{ marginTop: "20px", cursor: "pointer", color: "blue", textDecoration: "underline" }}
                  onClick={openPasswordRecoveryModal}
                >
                  Forgot password?
                </div>
                <div style={{ marginTop: "20px", cursor: "pointer", color: "blue", textDecoration: "underline" }}>
                  <a style={{ color: "blue" }}href="https://discord.gg/3pb8QcMrG5">Need Help?</a>
                </div>
                {showPasswordRecoveryModal && (
                  <PasswordRecoveryModal show={showPasswordRecoveryModal} onClose={() => setShowPasswordRecoveryModal(false)} />
                )}
              </Form>
            </Card.Body>
        </Card>
        
        
        <RegisterModal show={showRegistration} onHide={toggleRegistrationModal} />
        {showSubscriptionModal && (<Subscription showModal={showSubscriptionModal} closeModal={() => setShowSubscriptionModal(false)} username={usernameLogin || ''} />)}
        <div>
          <Card className="message-card" style={{marginTop: "50px"}}>
            <Card.Header><h4>Feedback from DzTools community</h4></Card.Header>
            <Card.Body style={{height:"450px"}}>
              <FeedbackMessages/>
            </Card.Body>
          </Card>
        </div>
    </div>
  );
};

export default Login;